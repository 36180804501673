import React, { useEffect, useState } from 'react';
import { useUpdatePropertyMutation } from '../store/docApi';
import Stepper from './Stepper';
import ColorChart from '../Widgets/ColorChart';
import { Button, Checkbox, Colors } from '@blueprintjs/core';
import ProjectTOC from './ProjectTOC';
import ProjectMotifs from '../Widgets/ProjectMotifs';
import Notes from '../Widgets/Notes';
import { appstyles } from '../styles/appstyles';
import Links from '../Widgets/Links';

export function ProjectWizard({ item, connectionIndex, stepIndex, pattern }) {
    const [currentSize, setCurrentSize] = useState();
    const [updateProperty] = useUpdatePropertyMutation();
    const [currentStep, setCurrentStep] = useState();
    const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex);
    const [progress, setProgress] = useState();
//    const [pattern, setPattern] = useState();
    const [showMotifView, setShowMotifView] = useState(true);
    const [isTOCOpen, setIsTOCOpen] = useState(false);

    useEffect(() => {
        if (item) {
            if (item.connections && item.connections.length > connectionIndex) {
                let connection = item.connections[connectionIndex];
                setProgress(connection.progress);
                setCurrentSize(connection.currentSize);
//                setPattern(connection.pattern);
            }
        }
    }, [item, connectionIndex]);

    useEffect(() => {
        if (pattern && pattern.steps && currentStepIndex >= 0 && currentStepIndex < pattern.steps.length) {
            let _pattern = pattern;
            let _currentStep = _pattern && _pattern.steps && _pattern.steps.length > 0 ? { ..._pattern.steps[currentStepIndex] } : -1;
            console.log("currentStep", _currentStep, _currentStep.data);
            _currentStep.stepIndex = currentStepIndex;
            if (_currentStep) {
                if (_currentStep.part_id && _pattern.parts) {
                    const part = _pattern.parts.find(p => p.id === _currentStep.part_id);
                    //console.log(_pattern.parts);
                    //console.log("MAIN part_id", _currentStep.part_id, "part: ", part && part.id, "index: ", _currentStep.stepIndex, currentStepIndex);
                    if (part) {
                        _currentStep = { ..._currentStep, ...part };
                    }
                }
            }
            setCurrentStep(_currentStep);
            window.scrollTo(0, 0);
        }
    }, [pattern, currentStepIndex]);

    function getProgress(id) {
        if (progress)
            return progress[id];
        else
            return "";
    }

    async function selectStep(stepid, index) {
        updateProgress(`${stepid}_step`, index);
    }

    async function selectOption(stepid, index) {
        updateProgress(`${stepid}_option`, index);
    };

    const handleNextStep = () => {
        setCurrentStepIndex((prev) => prev + 1);
    };

    const handlePrevStep = () => {
        setCurrentStepIndex((prev) => prev - 1);
    };

    async function updateProgress(name, value) {
        let newprogress = { ...progress };
        newprogress[name] = value;
        setProgress(newprogress);
        let updateParams = {
            label: "project", pk: item.pk, id: item.id,
            name: `connections/${connectionIndex}/progress/${name}`,
            value: value
        };
        await updateProperty(updateParams);
    }

    const NextPrevButtons = () => {
        return <div style={styles.buttonContainer}>
            {currentStepIndex === 0 ? <div /> :
                <Button style={{ marginRight: 32 }} icon='arrow-left' onClick={handlePrevStep}>Back</Button>}
            {currentStepIndex === pattern.steps.length - 1 ? <div /> :
                <Button icon='arrow-right' onClick={handleNextStep} >Next</Button>}
        </div>
    }

    const Steps = ({ steps, depth, parent }) => {
        const level = depth ? depth.toString().split('_').length : 0;
        return steps && steps.map((step, i) => {
            let stepid = depth ? depth : '';
            let optionStepid = depth ? `${depth}_${i}` : i;
            let stepprogress = getProgress(`${stepid}_step`);
            let selectedOption;
            let optionindex = getProgress(`${optionStepid}_option`) || 0;
            if (step.part_id && pattern.parts) {
                const part = pattern.parts.find(p => p.id === step.part_id);
                //console.log("STEPS part_id", step.part_id, "part: ", part && part.id);
                if (part) {
                    step = { ...step, ...part };
                }
            }
            if (step.options) {
                if (optionindex >= 0 && optionindex < step.options.length)
                    selectedOption = step.options[optionindex];
            }
            if (step.sizes && !step.sizes.includes(currentSize)) return <></>;

            return (
                <div key={i} style={{ marginLeft: level * 8, marginTop: 8 }}>
                    {step.title && <p style={styles.title}>{step.title}</p>}
                    {step.links && <Links links={step.links} />}
                    {step.note && <p>{step.note}</p>}
                    {step.notes && <Notes notes={step.notes} />}

                    {parent.multiselect ? <div>
                        <Checkbox checked={getProgress(`${stepid}_step_${i}`) === i}
                            onChange={() => updateProgress(`${stepid}_step_${i}`, i)}>
                            {step.name && step.verbal && <span>{step.name}</span>}
                            {!step.name && step.verbal && <span>{step.verbal}</span>}
                            {step.name && !step.verbal && <span>{step.name}</span>}
                        </Checkbox>
                        {step.name && step.verbal && <div>{step.verbal}</div>}
                    </div> :
                    <div onClick={() => selectStep(stepid, i)} style={stepprogress === i ? styles.selectedSubStep : styles.subStep}>
                        {step.name && !step.verbal && <p style={styles.name}>{step.name}</p>}
                        {<span style={appstyles.stepid}>{`${depth}_${i}`}</span>}
                        {step.verbal && <p style={styles.verbal}><span style={styles.name}>{step.name}</span> {step.verbal}</p>}
                        {step.text && step.params && <p>{formatString(step.text, step.params)}</p>}
                    </div>}

                    {step.options &&
                        <div style={styles.optionContainer}>
                            {step.options.map((option, j) => (
                                <Button key={j} onClick={() => selectOption(optionStepid, j)}
                                    intent={optionindex === j ? 'primary' : 'none'} >
                                    {option.name}</Button>
                            ))}
                        </div>}
                    {selectedOption && (
                        <div style={styles.optionDetails}>
                            {selectedOption.name && <p style={styles.name}>{selectedOption.name}</p>}
                            {selectedOption.verbal &&
                                <p onClick={() => selectStep(stepid, i)}
                                    style={stepprogress === i ? styles.selectedSubStep : styles.subStep}>
                                    {selectedOption.verbal}
                                </p>}
                            {selectedOption.steps &&
                                <Steps steps={selectedOption.steps} depth={`${depth}_${i}`} parent={selectOption} />
                            }
                        </div>
                    )}
                    {step.steps && <Steps steps={step.steps} depth={`${depth}_${i}`} parent={step} />}
                    {step.motifs && showMotifView && <ProjectMotifs motifIndex={currentStepIndex} item={step} isEdit={true} colors={item.colors} />}
                    {step.colorchart && !showMotifView && <ColorChart key={`${depth}_${i}`} item={step} depth={`${depth}_${i}`} viewonly={true} progress={progress} updateProgress={updateProgress} />}
                    {step.endnote && <p style={styles.note}>{step.endnote}</p>}
                    {step.endnotes && <Notes notes={step.endnotes} />}
                    {step.picture && <img src={step.picture} alt={step.name} style={styles.picture} />}
                </div>
            )
        })
    }

    const formatString = (str, params) => {
        return str.replace(/\${(.*?)}/g, (match, p1) => params[p1][currentSize] || '');
    };

    return pattern && pattern.steps && item && currentStep && (
        <div>
            {currentSize && <p>Size: {currentSize}</p>}
            <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', marginBottom: 16 }}>
                <Button minimal icon={isTOCOpen ? 'chevron-left' : 'chevron-right'} onClick={() => setIsTOCOpen(!isTOCOpen)} />
                <Stepper steps={pattern.steps} currentStep={currentStepIndex} onStepPress={setCurrentStepIndex} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ProjectTOC steps={pattern.steps} currentStep={currentStepIndex} onStepPress={setCurrentStepIndex}
                    isOpen={isTOCOpen} />
                <div>
                    {currentStep.title && <div style={styles.toptitle}>{currentStep.title}</div>}
                    {currentStep.name && <div style={styles.name}>{currentStep.name}</div>}
                    {currentStep.links && <Links links={currentStep.links} />}
                    {currentStep.note && <p>{currentStep.note}</p>}
                    {currentStep.notes && <Notes notes={currentStep.notes} />}
                    {currentStep.verbal && <p style={styles.verbal}>{currentStep.verbal}</p>}
                    {/*currentStep.colorchart && <ProjectMotifs motifIndex={currentStepIndex} item={item} />*/}
                    {currentStep.steps && <Steps steps={currentStep.steps} depth={currentStepIndex} parent={currentStep} />}
                    {currentStep.colorchart && !showMotifView &&
                        <ColorChart key={currentStep.stepIndex} item={currentStep} depth={currentStepIndex}
                            viewonly={true} progress={progress} updateProgress={updateProgress} />
                    }
                    {currentStep.motifs && showMotifView && <ProjectMotifs item={pattern} motifIndex={currentStepIndex} isEdit={true} colors={item.colors} />}
                    {currentStep.endnote && <p style={styles.note}>{currentStep.endnote}</p>}
                    {currentStep.endnotes && <Notes notes={currentStep.endnotes} />}
                    <NextPrevButtons />
                    <br /><br /><br />
                    <Checkbox checked={showMotifView} onChange={() => setShowMotifView(!showMotifView)}>Show Motif View</Checkbox>
                </div>
            </div>
        </div>
    );
};

const styles = {
    toptitle: {
        fontWeight: 'bold',
        marginBottom: 8,
    },
    title: {
        fontWeight: 600,
        marginBottom: 8,
        marginTop: 16,
    },
    name: {
        fontWeight: 550,
        marginBottom: 8
    },
    verbal: {
        //marginBottom: 8,
        marginTop: 16,
        padding: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey',
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
        //justifyContent: 'space-between',
        marginBottom: 8,
        gap: 4
    },
    optionButton: {
        flex: 1,
        padding: 10,
        marginHorizontal: 5,
        backgroundColor: '#a0d6b4',
        alignItems: 'center',
        borderRadius: 5,
        minWidth: 100
    },
    selectedOptionButton: {
        backgroundColor: 'teal',
    },
    optionButtonText: {
        color: 'white',
        textAlign: 'center'
    },
    optionDetails: {
        marginTop: 10,
        marginBottom: 20,
        padding: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
        borderColor: Colors.BLUE4,
    },
    optionInstructions: {
        fontSize: 16,
        color: 'darkblue',
    },
    subStep: {
        //fontSize: 16,
        //marginVertical: 5,
        //borderTopWidth: 1,
        //padding: 8,
        //borderColor: 'lightgrey',
        //fontSize: 'medium',
        cursor: 'pointer'
    },
    selectedSubStep: {
        //fontSize: 'medium',
        backgroundColor: 'lemonchiffon',
        cursor: 'pointer',
        borderColor: 'black'
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 20,
        display: 'flex'
    },
    progressContainer: {
        marginTop: 30,
    },
    progressHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    progressText: {
        fontSize: 16,
    },
    stepName: {
        fontWeight: 'bold'
    },
    picture: {
        width: '100%',
        maxWidth: 600,
        height: 'auto',
        marginBottom: 8
    },
};
