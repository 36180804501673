import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert, Button, Card, Spinner, Menu, MenuItem, Popover, Icon } from '@blueprintjs/core';
import { useGetItemQuery, useGetConnectedAllQuery, useDeleteItemMutation, useUpdatePropertyMutation } from '../store/docApi';
import Picture from '../Widgets/Picture';
import MotifCard from '../Motif/MotifCard';
import ItemMetadata from '../Widgets/ItemMetadata';
import ItemColors from '../Widgets/ItemColors';
import FileUpload from '../Widgets/FileUpload';
import { appstyles } from '../styles/appstyles';
import LayoutEditor from './LayoutEditor';
import ColorChartThumbnail from '../Widgets/ColorChartThumbnail';

export default function CollectionDetails() {
  let params = useParams();
  const navigate = useNavigate();
  const { data: item, error, isLoading } = useGetItemQuery({ container: 'design', label: "collection", pk: params.pk, id: params.id });
  const { data: motifs, isLoading: motifsLoading } = useGetConnectedAllQuery({
    _id: `collection/${params.pk}/${params.id}`, label: "motif"
  });
  const [deleteItem] = useDeleteItemMutation();
  const [updateProperty] = useUpdatePropertyMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("motifs");

  const [editLayoutIndex, setEditLayoutIndex] = useState();

  function deleteCollection() {
    setDeleteConfirmOpen(false);
    deleteItem({ label: "collection", pk: item.pk, id: item.id });
    navigate("/collections");
  }

  const addLayout = async () => {
    let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
    layouts.push({ id: "layout" + layouts.length, name: "New Layout " + layouts.length });
    await updateProperty({ label: "collection", pk: item.pk, id: item.id, name: "layouts", value: layouts });
    setEditLayoutIndex(layouts.length - 1);
    setSelectedTab("layouts");
  }

  const saveLayout = async (layout) => {
    let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
    layouts[editLayoutIndex] = layout;
    await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
    setEditLayoutIndex();
  }

  const deleteLayout = async () => {
    let layouts = item.layouts ? JSON.parse(JSON.stringify(item.layouts)) : [];
    layouts.splice(editLayoutIndex, 1);
    await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "layouts", value: layouts });
    setEditLayoutIndex();
  }

  const cancelEditLayout = () => {
    setEditLayoutIndex();
  }

  const actionMenu = (
    <Menu>
      <MenuItem minimal icon='trash' onClick={() => setDeleteConfirmOpen(true)} text="Delete collection" />
    </Menu>
  );
  const Motifs = () => {
    return <div>
      {item.description && <p>{item.description}</p>}
      <Picture picture={item.picture} pictures={item.pictures} />
      <ItemColors item={item} />
      <br />
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '16px' }}>
        <Card interactive onClick={() => navigate(`/motif/create/collection/${item.pk}/${item.id}`)}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 16 }}>
            <Icon icon="plus" size={32} />
            <b>Add new motif</b>
          </div>
        </Card>
        {motifsLoading ? <div>Loading...</div> :
          motifs && motifs.map((motif, i) =>
            <MotifCard key={i} motif={motif} colors={item.colors} />
          )}
      </div>
      <br />
      <FileUpload folder={item.pk} item={item} />
    </div>
  }

  const Layouts = () => {
    return <div>
      {editLayoutIndex === undefined && <div style={appstyles.tileGrid}>
        <Card style={appstyles.card} interactive onClick={() => addLayout()}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 16 }}>
            <Icon icon="plus" size={32} />
            <b>Add new layout</b>
          </div>
        </Card>
        {item.layouts && item.layouts.map((layout, i) =>
          <Card key={i} style={appstyles.tile} interactive onClick={() => setEditLayoutIndex(i)}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h3>{layout.name}</h3>
              {layout.numrows && <p>Rows: {layout.numrows}</p>}
              {layout.steps && layout.steps.map((step, j) =>
                <ColorChartThumbnail key={j} data={step.data} colors={layout.colors} mincols={24} />
              )}
            </div>
          </Card>
        )}
      </div>}
      <br />
      {editLayoutIndex !== undefined && editLayoutIndex < item.layouts.length && <div>
        <LayoutEditor availableSteps={motifs} initialLayout={item.layouts[editLayoutIndex]} initialColors={item.layouts[editLayoutIndex].colors}
          onSave={saveLayout} onDelete={deleteLayout} onCancel={cancelEditLayout} />
      </div>}
    </div>
  }

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner style={appstyles.spinner}>Loading...</Spinner>) :
      <div style={appstyles.content}>
        <h2>{item.name}</h2>
        <div style={appstyles.tabContainer}>
          <div style={appstyles.tabButtonGroup}>
            <Button minimal style={selectedTab === "motifs" ? appstyles.activeTabButton : appstyles.tabButton}
              onClick={() => setSelectedTab("motifs")}>Motifs</Button>
            <Button minimal style={selectedTab === "layouts" ? appstyles.activeTabButton : appstyles.tabButton}
              onClick={() => setSelectedTab("layouts")}>Layouts</Button>
          </div>
          <div style={appstyles.tabButtonGroup}>
            <Popover content={actionMenu} position="bottom">
              <Button minimal icon="more" />
            </Popover>

          </div>
        </div>
        <br />
        {selectedTab === "motifs" && <Motifs />}
        {selectedTab === "layouts" && <Layouts />}
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
          isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
          onConfirm={deleteCollection}>
          <p>Delete this collection?</p>
        </Alert>
      </div>
}
