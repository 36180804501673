import  { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useUpdatePropertyMutation } from '../store/docApi';
import { Colors } from '../styles';
import Stitches from './Stitches';
import Links from '../Widgets/Links';
import Picture from '../Widgets/Picture';
import ColorChart from '../Widgets/ColorChart';

export default function Steps({ palette, steps, item, subitems, project, depth, size, expandAll, showVerbal }) {
    const [updateProperty] = useUpdatePropertyMutation()
    const [isOpen, setIsOpen] = useState({});

    function showStep(stepId) {
        let newIsOpen = { ...isOpen };
        newIsOpen[stepId] = !isOpen[stepId];
        setIsOpen(newIsOpen);
    }

    function getSteps(steps_id) {
        let steps = subitems.find(s => s.id === steps_id);
        return steps ? steps.steps : [];
    }

    function getStepColor(name) {
        if (palette === undefined || palette.shades === undefined) return Colors.OffWhite;
        let shade = palette.shades.find(shade => shade.name === name);
        return shade ? shade.color : 'white';
    }

    function getProgress(id) {
        if (project && project.progress)
            return project.progress[id];
        else
            return "";
    }

    function getSizeValue(value) {
        if (Array.isArray(value) && value.length > 0) {
            return value[0];
        }
        else if (typeof value == 'object') {
            return size ? value[size] : "";
        } else
            return value;
    }

    const setSelection = async (stepid, stepprogress) => {
        let newprogress = "";
        if (stepprogress === "started")
            newprogress = "done";
        else if (stepprogress === "done")
            newprogress = "";
        else
            newprogress = "started"
        let updateParams = { label: "project", pk: project.pk, id: project.id, name: `progress/${stepid}`, value: newprogress };
        await updateProperty(updateParams);
    }

    return (
        <div>
            {steps && steps.map((step, i) => {
                let stepid = depth ? `${depth}_${i}` : i;
                let stepprogress = getProgress(stepid);
                return (
                    <div key={i} style={styles.card}>
                        {step.hook && <div><span style={styles.name}>Hook:</span> {step.hook}</div>}
                        {step.needles && <div><span style={styles.name}>Needles:</span> {step.needles}</div>}
                        {step.name && <h4>{step.name}</h4>}
                        {false && <div style={styles.step}>
                            {step.yarn && <div style={{ ...styles.yarn, backgroundColor: getStepColor(step.yarn) }} />}
                            <Button minimal style={styles.icon} icon={stepprogress === 'started' ? 'ring' : stepprogress === 'done' ? 'tick-circle' : 'circle'}
                                intent={stepprogress === 'started' ? 'danger' : stepprogress === 'done' ? 'success' : 'none'}
                                onClick={() => setSelection(stepid, stepprogress)} />
                            <Button minimal small onClick={() => showStep(stepid, true)} icon={isOpen[stepid] ? 'chevron-down' : 'chevron-right'}>
                                {step.facing && <span style={step.facing === 'RS' ? styles.facingRS : styles.facingWS}> {step.facing}</span>}
                                {step.name ? <span style={styles.name}> {step.name}</span> :
                                    step.repeat ? <span>Repeat {getSizeValue(step.repeat)} times</span> : <span />}
                            </Button>
                        </div>}
                        {showVerbal && step.verbal &&
                            <p style={stepprogress === "started" ? styles.verbalHighlight : styles.verbal}>
                                {step.verbal}
                            </p>
                        }
                        {(expandAll || isOpen[stepid]) &&
                            <div style={styles.stepDetails}>
                                {step.yarn && <div>Yarn: {step.yarn}</div>}
                                <Links links={step.links} />
                                {step.note && <span>{step.note}</span>}
                                {step.steps && <Steps showVerbal={showVerbal} palette={palette} steps={step.steps} item={item} subitems={subitems} project={project} depth={stepid} size={size} />}
                                {step.steps_id && <Steps palette={palette} steps={getSteps(step.steps_id)} item={item} subitems={subitems} project={project} depth={stepid} size={size} />}
                                <Stitches stitches={step.stitches} depth={stepid} size={size} />
                                {step.endnote && <div>{step.endnote}</div>}
                                {step.pictures && <Picture pictures={step.pictures} />}
                                {step.colorchart_id && <ColorChart steps={getSteps(getSizeValue(step.colorchart_id))} item={item} palette={palette} />}
                                {step.repeat &&
                                    <div>Repeat: &nbsp;
                                        {Array(getSizeValue(step.repeat)).fill({}).map((c, j) => <Button small key={j}> {j + 1} </Button>)}
                                    </div>
                                }
                                {step.total && <div style={styles.total}>Total: {getSizeValue(step.total)}</div>}
                            </div>
                        }
                        <div style={styles.stepid}>{stepid}</div>
                    </div>
                )
            })}
        </div>
    );
}

const styles = {
    card: {
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderColor: 'lightgrey',
    },
    icon: {
        paddingRight: 5,
        cursor: 'pointer'
    },
    step: {
        display: 'flex',
        flexDirection: 'row'
    },
    stepDetails: {
        borderLeftWidth: 1,
        borderColor: 'lightslategrey',
        marginHorizontal: 4,
        paddingHorizontal: 4
    },
    yarn: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'lightgrey',
        width: 8,
        height: 24,
        display: 'inline-block',
        alignSelf: 'center'
    },
    name: {
        fontWeight: 'bold'
    },
    verbal: {
        backgroundColor: 'white'
    },
    verbalHighlight: {
        backgroundColor: 'yellow'
    },
    total: {
        alignSelf: 'flex-end'
    },
    facingRS: {
        color: Colors.DarkBlue,
        alignSelf: 'center'
    },
    facingWS: {
        color: Colors.Magenta,
        alignSelf: 'center'
    },
    stepid: {
        fontSize: '8px',
        textAlign: 'right'
    }
};
