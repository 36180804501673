import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useGetItemsQuery, useGetItemQuery, useShareItemMutation, useUnshareItemMutation } from "../store/docApi";
import { useDeletePartMutation } from "../store/appApi";
import { useUpdatePropertyMutation } from "../store/docApi";
import Picture from "../Widgets/Picture";
import Steps from "../components/Steps";
import KnitChart from "../Widgets/KnitChart";
import ColorChart from "../Widgets/ColorChart";
import Links from "../Widgets/Links";
import { InputGroup, Alert, Button, Dialog } from "@blueprintjs/core";
import ItemMetadata from "../Widgets/ItemMetadata";
import ColorChartCanvas from "../Widgets/ColorChartCanvas";
import ColorChartThumbnail from "../Widgets/ColorChartThumbnail";
import FileUpload from "../Widgets/FileUpload";
import { _getItemId } from "../utils";
import { appstyles } from "../styles/appstyles";

export default function MotifDetails() {
    let params = useParams();
    const { data: item } = useGetItemQuery({ container: 'design', label: 'part', pk: params.pk, id: params.id });
    const { data: collections, error: collectionsError, isLoading: collectionsIsLoading } = useGetItemsQuery({ container: 'design', label: 'collection' })
    const [shareItem] = useShareItemMutation();
    const [unshareItem] = useUnshareItemMutation();
    const [deletePart] = useDeletePartMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [addToCollectionOpen, setAddToCollectionOpen] = useState(false);
    const [shareUserId, setShareUserId] = useState("");

    function deleteConfirm() {
        setDeleteConfirmOpen(true);
    }

    function deleteMotif() {
        setDeleteConfirmOpen(false);
        deletePart({ label: "part", pk: item.pk, id: item.id });
        navigate("/motifs");
    }

    function addToCollection(collection) {
        const _id = _getItemId(collection);
        if (item.connections && item.connections.find(c => c._id === _id)) {
            return;
        }
        let newConnections = item.connections ? [...item.connections] : [];
        newConnections.push({ _id: _id });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'connections', value: newConnections });
        setAddToCollectionOpen(false);
    }

    function onSaveProperty(name, value) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: name, value: value });
    }

    function onShareItem() {
        shareItem({ label: item.label, pk: item.pk, id: item.id, userid: shareUserId });
    }

    function onUnshareItem(userid) {
        unshareItem({ label: item.label, pk: item.pk, id: item.id, userid: shareUserId });
    }

    return item &&
        <div style={appstyles.content}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                {item.name && <h2>{item.name}</h2>}
                <span style={appstyles.tabButtonGroup}>
                    <Button icon='box' text="Add to Collection" onClick={() => setAddToCollectionOpen(true)} />
                    &nbsp;
                    <Button icon='trash' onClick={() => deleteConfirm()} />
                </span>
            </div>
            {item.author && <div>Designed by {item.author} </div>}
            {item.cols > 1 && <div>Multiple of {item.cols} sts {item.extracols && <span>plus {item.extracols}</span>}</div>}
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description}
            {false && item.colorchart && <ColorChart item={item} chartid={item.id} />}
            {item.colorchart && <ColorChartThumbnail data={item.data} colors={item.colors} mincols={24} />}
            {item.knitchart && item.steps && <div><br /><KnitChart steps={item.steps} item={item} chartid={item.id} /></div>}
            {item.steps && <Steps steps={item.steps} showVerbal={true} expandAll={true} />}
            <Links links={item.links} />
            {item.sources && item.sources.map((source, i) => {
                return <div key={i}>{source.id} {source.chapter} {source.page}</div>
            })}
            {item.connections && <h4>Collections</h4>}
            {item.connections && item.connections.map((connection, i) => {
                return <div key={i}>
                    <Link to={"/" + connection._id} onClick={() => navigate("/" + connection._id)}>{connection._id}</Link>
                    <Button minimal icon='trash' onClick={() => updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'connections', value: item.connections.filter((c, j) => j !== i) })} />
                </div>
            })}
            {item.isPartOf && item.isPartOf._id && <div>
                <b>Part of</b> <Link to={"/" + item.isPartOf._id}>{item.isPartOf._id}</Link>
            </div>}
            <ColorChartCanvas colors={item.colors} data={item.data} onSaveProperty={onSaveProperty} />
            <FileUpload folder={item.pk} item={item} />
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => deleteMotif()}>
                <p>Delete this motif?</p>
            </Alert>
            <Dialog isOpen={addToCollectionOpen} onClose={() => setAddToCollectionOpen(false)} title="Add to Collection" isCloseButtonShown={true} canOutsideClickClose={true}>
                <div style={{ padding: "20px" }}>
                    <p>Choose a collection to add this motif to</p>
                    {collectionsError && <div>Error loading collections</div>}
                    {collectionsIsLoading && <div>Loading collections...</div>}
                    {collections && collections.map((collection, i) => {
                        return <div key={i} style={{ margin: 16 }}>
                            <Button icon='box' text={collection.name} onClick={() => addToCollection(collection)} />
                        </div>
                    })}
                </div>
            </Dialog>
            <h3>Sharing</h3>
            Shared with: {item.sharedwith && item.sharedwith.map((user, i) => {
                    return <span key={i}>{user}</span>
                })}
            <div style={{ display: "flex" }}>
                <InputGroup placeholder="User ID" value={shareUserId} onChange={(e) => setShareUserId(e.target.value)} />
                &nbsp;
                <Button text="Share" onClick={() => onShareItem()} />
                &nbsp;
                <Button text="Unshare" onClick={() => onUnshareItem()} />
            </div>
            <ItemMetadata item={item} />
        </div>
}
