import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, Colors, InputGroup, Spinner, Tooltip } from '@blueprintjs/core';
import { useGetItemQuery, useImportPartMutation, useDeleteImportPartMutation, usePublishMutation } from '../store/docApi';
import ColorChartThumbnail from '../Widgets/ColorChartThumbnail';
import Instructions from './Instructions';
import Overview from './Overview';
import ProductCreate from '../Shop/ProductCreate';
import { appstyles } from '../styles/appstyles';
import ItemMetadata from '../Widgets/ItemMetadata';

export default function Pattern() {
  let params = useParams();
  const patternPk = params.pk;
  const patternId = params.id;

  // TODO: do we need projectId and stepId?
  const projectId = params.projectId;
  const stepId = params.stepId;

  const { data: item, isLoading } = useGetItemQuery({ container: 'design', label: 'pattern', pk: params.pk, id: params.id });
  const [publish, result] = usePublishMutation();
  const [importPart] = useImportPartMutation();
  const [deleteImportPart] = useDeleteImportPartMutation();

  const [showPublish, setShowPublish] = useState(false);
  const [selectedTab, setSelectedTab] = useState(params.projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const [statusMessage, setStatusMessage] = useState("");
  const [partPk, setPartPk] = useState("");
  const [partId, setPartId] = useState("");
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      borderColor: Colors.TURQUOISE3,
      //color: 'white',
      borderWidth: "0 0 4px 0", borderStyle: 'solid',
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  function onSell() {
    setShowPublish(true);
  }

  async function onPublish() {
    await publish({ label: "pattern", pk: patternPk, id: patternId })
  }

  useEffect(() => {
    if (result.isSuccess) {
      setStatusMessage("Published.");
    }
  }, [result]);

  async function onImportPart() {
    await importPart({ label: "pattern", pk: patternPk, id: patternId, sourcepk: partPk, sourceid: partId });
  }

  async function onDeleteImportPart() {
    await deleteImportPart({ label: "pattern", pk: patternPk, id: patternId, sourcepk: partPk, sourceid: partId });
  }

  return isLoading ? <Spinner style={appstyles.spinner} /> :
    item && <div style={appstyles.content}>
      <div>
        <div>
          <h2>{item.name}</h2>
          {/*node && <i> ({node.name} {node.stripeProductId && node.stripeProductId})</i>*/}
          {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
        </div>
        <div>
          {item.lang && item.lang.map((lang, i) =>
            <Button small key={i} style={tabStyle(lang === currentLang)}
              onClick={() => setCurrentLang(lang)}>{lang}</Button>
          )}
        </div>
      </div>
      <div style={appstyles.tabContainer}>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal style={selectedTab === "overview" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("overview")}>Overview</Button>
          <Button minimal style={selectedTab === "instructions" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("instructions")}>Instructions</Button>
          <Button minimal style={selectedTab === "parts" ? appstyles.activeTabButton : appstyles.tabButton}
            onClick={() => setSelectedTab("parts")}>Parts</Button>
        </div>
        <div style={appstyles.tabButtonGroup}>
          <Button minimal icon="edit" onClick={() => edit()} disabled />
          <Tooltip content="Publish" placement="bottom">
            <Button minimal icon="cloud-upload" onClick={() => onPublish()} />
          </Tooltip>
          <Tooltip content="Sell" placement="bottom">
            <Button minimal icon="shop" onClick={() => onSell()} />
          </Tooltip>
        </div>
      </div>
      <br />
      {statusMessage && <Callout intent="success" style={{ marginTop: 16 }}>{statusMessage}</Callout>}
      {showPublish && <ProductCreate item={item} />}
      <br />
      {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
      {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} parts={item.parts} />}
      {selectedTab === "parts" &&
        <div>
          {item.parts && item.parts.map((part, i) => {
            return <div key={i}>
              {part.label} <Link to={`/part/${part._pk}/${part._id}`}>{part.name}</Link> ({part.id}) ({part._pk}/{part._id})
              {part.colorchart && part.data && <ColorChartThumbnail data={part.data} colors={part.colors} mincols={24} />}
            </div>
          })}
          <br/>
          <div style={{ display: "flex" }}>
            <InputGroup placeholder="Part PK" value={partPk} onChange={(e) => setPartPk(e.target.value)} />
            &nbsp;
            <InputGroup placeholder="Part ID" value={partId} onChange={(e) => setPartId(e.target.value)} />
            &nbsp;
            <Button text="Import Part" onClick={() => onImportPart()} />
            &nbsp;
            <Button text="Delete Imported Part" onClick={() => onDeleteImportPart()} />
          </div>

        </div>}
      <ItemMetadata item={item} />
    </div>
}
