import { useState } from 'react';
import { Button, Callout, ControlGroup, InputGroup } from '@blueprintjs/core';
import { useCompleteMutation } from '../store/openaiApi';

export default function Complete() {
  const [prompt, setPrompt] = useState('');
  const [message, setMessage] = useState('');
  const [complete] = useCompleteMutation();


  function handleCompleteClick() {
    complete({ prompt: prompt }).unwrap().then((result) => {
      console.log(result)
      if (result && result.error) {
        setMessage("Error " + result.error.status);
      } else {
        setMessage(result.value);
        console.log(result.value)
      }
    })
  };

  return (
    <div>
      <ControlGroup>
        <InputGroup style={styles.input} name="prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
        <Button intent="primary" onClick={() => handleCompleteClick(true)} >Complete</Button>
      </ControlGroup>
      <ControlGroup>
      <Callout style={styles.callout}><pre style={styles.message}>{message}</pre></Callout>
      <Button icon="duplicate" minimal="true" onClick={() => navigator.clipboard.writeText(message.trim())}></Button>      </ControlGroup>
    </div>
  );
}

const styles = {
  callout: {
    width: 500,
  },
  message: {
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  input: {
    width: 500
  }
}
