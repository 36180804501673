import { useNavigate } from "react-router-dom";
import { Card } from "@blueprintjs/core";
//import { store } from "../../store/store";
//import { showMotifAction } from "../../store/motifSlice";
import ColorChartThumbnail from "../Widgets/ColorChartThumbnail";
import KnitChart from "../Widgets/KnitChart";
import { appstyles } from "../styles/appstyles";

export default function MotifCard({ motif, colors }) {
  const navigate = useNavigate();
/*
  function showMotifDetails() {
    store.dispatch(showMotifAction({
      show: true,
      motif: motif
    }));
  }
*/
  return (
    <Card interactive style={styles.card} onClick={() => navigate(`/motif/${motif.pk}/${motif.id}`)}>
      {motif.picture && <img style={styles.img} src={motif.picture} alt={motif.name} />}
      {motif.colorchart && motif.data && <ColorChartThumbnail data={motif.data} colors={colors || motif.colors} mincols={24} />}
      {motif.knitchart && <KnitChart steps={motif.steps} item={motif} chartid={motif.id} thumbnail={true} />}
      <div style={styles.name}>
          {motif.name ? motif.name : motif.id}
      </div>
      <div style={appstyles.id}>{motif.id}</div>
    </Card>
  )
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  img: {
    maxHeight: 100,
    maxWidth: 100,
  },
  name: {
    textAlign: 'center',
    marginTop: 8
  }
}
