import { useNavigate } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { useGetItemsQuery, useGetSubitemsQuery } from '../store/docApi';

export default function ItemList({ itemLabel, itemPk, itemId }) {
  const navigate = useNavigate();

  let currentItemId = itemPk ? itemPk : ''
  let currentId = itemId ? itemId : ''
  const { data: items } = useGetItemsQuery({label: itemLabel})
  const { data: subitems } = useGetSubitemsQuery({label: itemLabel, pk: currentId }, {skip: currentId === ''});

  function showItemInfo(item) {
    navigate(`/item/${item.label}/${item.pk}/${item.id}`)
  }
  function showSubitemInfo(item, subitem) {
    navigate(`/item/${item.label}/${subitem.pk}/${subitem.id}`)
  }

  return (
    <div style={styles.panel}>
      <Button onClick={()=>navigate(`/item/${itemLabel}/create`)}>Create</Button>
      {items && items.map(item =>
        <div key={`${item.pk}_${item.id}`}>
          <Button onClick={() => showItemInfo(item)}
            minimal="true" intent={currentId === item.id ? "primary" : "none"}>{item.name ? item.name : item.label}</Button>
          {currentItemId === item.pk && subitems && subitems.length > 0 &&
            <div>
              {subitems.map(subitem => subitem.pk === item.id &&
                <div key={`${subitem.pk}_${subitem.id}`}>
                  <Button onClick={() => showSubitemInfo(item, subitem)} icon="dot"
                    minimal="true" intent={currentId === subitem.id ? "primary" : "none"}
                  >{subitem.name} ({subitem.label})</Button>
                </div>
              )}
            </div>}
        </div>
      )}
    </div>
  )
}

const styles = {
  panel: {
    minWidth: 100
  }
}
