import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Checkbox, Dialog, DialogBody, Divider, FormGroup, InputGroup, Label, Section, TextArea } from '@blueprintjs/core';
import { useGetItemQuery, useGetRelatedQuery, useUpdatePropertyMutation } from '../store/docApi';
import { useGetProjectQuery } from "../store/appApi";
import { Colors } from "../styles";
import { formatDate } from "../utils";
import Notes from "../Widgets/Notes";
import PartDetails from "../components/PartDetails";
import ProjectMotifs from "../Widgets/ProjectMotifs";
import ItemColors from "../Widgets/ItemColors";
import ColorChartThumbnail from "../Widgets/ColorChartThumbnail";

export default function Instructions({ patternPk, patternId, projectId, stepId, parts }) {
    const { data: item } = useGetItemQuery({ container: 'design', label: 'pattern', pk: patternPk, id: patternId });
    const { data: datasubitems } = useGetRelatedQuery({ name: "isPartOf", value: `pattern/${patternPk}/${patternId}`, container: "design", pk: patternPk })
    const [updateProperty] = useUpdatePropertyMutation();
    // const { data: projects } = useGetRelatedQuery({ name: "isBasedOn", value: `howto/${pk}/${id}`, container: "user", pk: "usr" });
    // const { data: notes, refetch: refetchNotes } = useGetConnectedQuery({ _id: `${label}/${pk}/${id}`, container: "note", pk: "usr" });
    const { data: project } = useGetProjectQuery({ pk: "usr", id: projectId }, { skip: !projectId })
    const [currentSubitem, setCurrentSubitem] = useState();
    const [currentStepId, setCurrentStepId] = useState();
    const [selectedStepId, setSelectedStepId] = useState(stepId);
    const [editStepIndex, setEditStepIndex] = useState(-1);
    const [editSubstepIndex, setEditSubstepIndex] = useState(-1);
    const [editStep, setEditStep] = useState();
    const [steps, setSteps] = useState([]);
    const [isEditOpen, setIsEditOpen] = useState(false);
    if (item) document.title = item.name;

    let subitems = [];
    if (datasubitems) {
        subitems = [...datasubitems]
        subitems.sort((p1, p2) => { return p1.index && p2.index ? p1.index - p2.index : 0 });
    }

    let palette = subitems ? subitems.find(s => s.label === "palette") : undefined;

    useEffect(() => {
        if (item && item.steps) {
            setSteps(item.steps);
        }
        //if (item && item.currentRow) setHighlightRow(item.currentRow);
    }, [item]);


    function getProgress(id) {
        if (project && project.progress)
            return project.progress[id];
        else
            return "";
    }

    function getStepBgColor(startdate, stepprogress) {
        let now = Date.now();
        let date = new Date(startdate);
        return now <= date ? 'whitesmoke' :
            stepprogress === 'started' ? Colors.ChartProgressStarted :
                stepprogress === 'done' ? Colors.ChartProgressDone : 'lavender';
    }

    function showSection(subitem, stepid) {
        setCurrentSubitem(subitem === currentSubitem ? '' : subitem);
        setCurrentStepId(stepid);
    }

    function selectStep(stepid) {
        setSelectedStepId(stepid);
    }

    const handleAddStep = (index) => {
        const newstep = {};
        const newsteps = JSON.parse(JSON.stringify(steps));
        if (index >= 0) {
            // add a substep
            if (!newsteps[index].steps) {
                newsteps[index].steps = [];
            }
            newsteps[index].steps.push(newstep);
        }
        else {
            newsteps.push(newstep);
        }
        setSteps(newsteps);
        saveSteps(newsteps);
    };

    const handleEditStep = (i, j) => {
        if (i >= 0 && j >= 0) {
            setEditStepIndex(i);
            setEditSubstepIndex(j);
            setEditStep(JSON.parse(JSON.stringify(steps[i].steps[j])));
        }
        else if (i >= 0) {
            setEditStepIndex(i);
            setEditStep(JSON.parse(JSON.stringify(steps[i])));
        }
        setIsEditOpen(true);
    }

    const setValue = (name, value) => {
        setEditStep({ ...editStep, [name]: value });
    }
    const handleSaveStep = () => {
        setIsEditOpen(false);
        const newsteps = JSON.parse(JSON.stringify(steps));
        // trim empty values from editStep
        Object.keys(editStep).forEach(key => {
            if (editStep[key] === "" || editStep[key] === false) {
                delete editStep[key];
            }
        });
        if (editStepIndex >= 0 && editSubstepIndex >= 0) {
            newsteps[editStepIndex].steps[editSubstepIndex] = editStep;
        }
        else {
            newsteps[editStepIndex] = editStep;
        }
        setSteps(newsteps);
        saveSteps(newsteps);
        setEditStepIndex(-1);
        setEditSubstepIndex(-1);
    }

    const handleCancelEdit = () => {
        setIsEditOpen(false);
        setEditStepIndex([-1, -1]);
    }

    const handleDeleteStepDialog = () => {
        setIsEditOpen(false);
        const newsteps = JSON.parse(JSON.stringify(steps));
        if (editStepIndex >= 0 && editSubstepIndex >= 0) {
            newsteps[editStepIndex].steps.splice(editSubstepIndex, 1);
        } else {
            newsteps.splice(editStepIndex, 1);
        }
        setSteps(newsteps);
        saveSteps(newsteps);
    };

    async function saveSteps(newsteps) {
        await updateProperty({ label: item.label, pk: item.pk, id: item.id, name: `steps`, value: newsteps });
    }

    function PartCard(step, i, stepid) {
        let stepprogress = getProgress(stepid);
        let subitem = subitems.find(s => s.id === step.part_id);
        let cardname = step.name ? step.name : subitem && subitem.name ? subitem.name : "";
        let cardpicture = step.picture ? step.picture : subitem && subitem.picture ? subitem.picture : null;
        let cardyarn = step.yarn ? step.yarn : subitem && subitem.yarn ? subitem.yarn : null;
        //let palette = subitems.find(s => s.label === 'palette');
        let yarncolor = Colors.OffWhite;
        if (palette && palette.shades) {
            let shade = palette.shades.find(shade => shade.name === cardyarn);
            if (shade) yarncolor = shade.color;
        }
        let card = (
            <div key={i}>
                <Card key={i} interactive={!!subitem || step.verbal || step.notes} style={{ padding: 0, margin: 10 }}
                    onClick={() => subitem ? showSection(subitem, stepid) : selectStep(stepid)}>
                    <div style={{
                        ...styles.card,
                        backgroundColor: subitem ? getStepBgColor(step.startdate, stepprogress) : 'white',
                        borderColor: selectedStepId === stepid ? 'orange' : 'white'
                    }}>
                        <div style={styles.row}>
                            {cardyarn && <div style={{ backgroundColor: yarncolor, width: 10 }}></div>}
                            {cardpicture && <img style={styles.picture} src={cardpicture} alt={cardpicture} />}
                            <div style={styles.step}>
                                <h4>
                                    {cardname}
                                    {step.repeat ? ' x ' : ''} {step.repeat}
                                </h4>
                                <Notes notes={step.notes} />
                                {step.repeat && <div style={styles.repeat}>
                                    {Array(step.repeat).fill(0).map((r, j) => {
                                        let stepidR = `${stepid}_${j}`;
                                        let stepprogressR = getProgress(stepidR);
                                        return <Card key={j} style={{ ...styles.minicard, backgroundColor: subitem ? getStepBgColor(step.startdate, stepprogressR) : 'whitesmoke' }}>
                                            {j + 1}
                                        </Card>
                                    })}
                                </div>}
                                {step.verbal && <p>{step.verbal}</p>}
                                {step.options &&
                                    <div style={styles.optionContainer}>
                                        {step.options.map((option, j) => (
                                            <Button key={j} >{option.name}</Button>
                                        ))}
                                    </div>}
                                <div style={styles.stepid}>{stepid}</div>
                            </div>
                        </div>
                    </div>
                </Card>
                {currentSubitem && subitem && currentSubitem.id === subitem.id && currentStepId === stepid &&
                    <div>
                        <b>{currentSubitem.name}</b>
                        <PartDetails item={item} subitem={currentSubitem} subitems={subitems} project={project} palette={palette} depth={stepid} />
                    </div>}
            </div>
        );
        return card;
    }

    function EditablePartCard(step, i, j, stepid) {
        let palette = subitems.find(s => s.label === 'palette');
        let yarncolor = Colors.OffWhite;
        if (palette && palette.shades) {
            let shade = palette.shades.find(shade => shade.name === step.yarn);
            if (shade) yarncolor = shade.color;
        }
        let card = (
            <div key={`${i}_${j}`} style={{ paddingLeft: 8, paddingRight: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {step.title ? <h4>{step.title} {step.repeat ? ' x ' : ''} {step.repeat}</h4> : <span />}
                        </div>
                        <div style={styles.row}>
                            {step.startdate && <div style={styles.stepdate}>{formatDate(step.startdate)}</div>}
                            <Notes notes={step.notes} />
                            {step.yarn && <div style={{ backgroundColor: yarncolor, width: 10 }}></div>}
                            {step.picture && <img style={styles.picture} src={step.picture} alt="" />}
                        </div>
                        {step.name && step.verbal ? <p><b>{step.name}</b> {step.verbal}</p> : step.name ? <p><b>{step.name}</b></p> : step.verbal ? <p>{step.verbal}</p> : ''}
                        {step.options &&
                            <div style={styles.optionContainer}>
                                {step.options.map((option, j) => (
                                    <Button key={j} >{option.name}</Button>
                                ))}
                            </div>}
                        <Notes notes={step.endnotes} />
                        {step.colorchart && <ColorChartThumbnail data={step.data} colors={step.colors} />}
                    </div>
                    <Button minimal icon="edit" onClick={(e) => { e.stopPropagation(); handleEditStep(i, j)}}
                        style={{ alignSelf: 'flex-start' }} />
                </div>
                <div style={styles.stepid}>{stepid}</div>
                <Divider />
            </div>
        );
        return card;
    }

    function StepEditor() {
        return (<div style={{ margin: 16 }}>
            <FormGroup>
                <Label>Title</Label>
                <InputGroup type="text" value={editStep.title} onChange={(e) => setValue('title', e.target.value)} />
                <Label>Part</Label>
                <div className="bp3-select">
                    <select value={editStep.part_id} onChange={(e) => setValue('part_id', e.target.value)}>
                        <option value="">Select a part...</option>
                        {parts && parts.map((part) => (
                            <option key={part.id} value={part.id}>
                                {part.name}
                            </option>
                        ))}
                    </select>
                </div>
                <Label>Notes</Label>
                <TextArea type="text" value={editStep.notes} onChange={(e) => setValue('notes', e.target.value)}
                    style={{ width: "100%" }} />
                <Label>Name</Label>
                <InputGroup type="text" value={editStep.name} onChange={(e) => setValue('name', e.target.value)} />
                <Label>Instructions</Label>
                <InputGroup type="text" value={editStep.verbal} onChange={(e) => setValue('verbal', e.target.value)} />
                <Label>Start Date</Label>
                <InputGroup type="datetime-local" value={editStep.startdate} onChange={(e) => setValue('startdate', e.target.value)} />
                <Label>End notes</Label>
                <TextArea type="text" value={editStep.endnotes} onChange={(e) => setValue('endnotes', e.target.value)}
                    style={{ width: "100%" }} />
                <Checkbox checked={editStep.motifs} onChange={(e) => setValue('motifs', e.target.checked)} >
                    Motifs
                </Checkbox>
                <Label>Mincols</Label>
                <InputGroup type="number" value={editStep.mincols} onChange={(e) => setValue('mincols', e.target.value)} />
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button icon="trash" intent="danger" onClick={() => handleDeleteStepDialog(editStepIndex, editSubstepIndex)}>Delete</Button>
                    <span>
                        <Button icon="floppy-disk" intent="primary" onClick={() => handleSaveStep()}>Save</Button>
                        &nbsp;&nbsp;
                        <Button onClick={() => handleCancelEdit()}>Cancel</Button>
                    </span>
                </div>
            </FormGroup>
        </div>
        )
    }

    return <div>
        {item && (<div>
            <ItemColors item={item} />
            <h3>Instructions</h3>
            {steps && steps.map((istep, i) => {
                return <div key={i} style={{ marginBottom: 16 }}>
                    <Section title={istep.title ? istep.title : ' '} subtitle={istep.name}
                        collapsible={true} collapseProps={{ defaultIsOpen: false }}
                        rightElement={<Button minimal icon="edit" onClick={(e) => { e.stopPropagation(); handleEditStep(i)}} />} >
                        {istep.startdate && <div>{formatDate(istep.startdate)}</div>}
                        {istep.part_id && <div>{istep.part_id}</div>}
                        {PartCard(istep, i, `${item.id}_${i}`)}
                        {istep.steps && istep.steps.map((jstep, j) => {
                            return EditablePartCard(jstep, i, j, `${item.id}_${i}_${j}`)
                        })}
                        {istep.motifs && <ProjectMotifs item={item} motifIndex={i} isEdit={true} colors={item.colors} />}
                        <br />
                        <Button icon="plus" onClick={() => handleAddStep(i)} text="Add step" />
                    </Section>
                </div>
            })}
            <br />

            <ul>
                {
                    subitems.map((subitem, i) => {
                        return (
                            <li key={i}>
                                <Link onClick={() => showSection(subitem)}>
                                    {subitem.name && subitem.name} ({subitem.label && subitem.label}) {subitem.index && subitem.index}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
            <Button icon="plus" onClick={handleAddStep}>Add Section</Button>
        </div>
        )}
        <Dialog isOpen={isEditOpen} onClose={handleCancelEdit} canOutsideClickClose title="Edit Step" style={{ width: "90%", height: "90%" }}>
            <DialogBody>
                {editStep && StepEditor()}
            </DialogBody>
        </Dialog>

    </div>
}

const styles = {
    row: {
        display: 'flex'
    },
    sections: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'stretch',
        justifyContent: "space-evenly"
    },
    card: {
        marginHorizontal: 4,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    repeat: {
        display: 'flex',
        flexDirection: 'row'
    },
    minicard: {
        margin: 5,
        padding: 10
    },
    step: {
        paddingLeft: 10,
    },
    stepid: {
        fontSize: 8,
        color: 'grey',
        textAlign: 'right'
    },
    picture: {
        height: 100,
        //width: 100,
        margin: 2
    },
    text: {
        margin: 1
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
        gap: 4
    },
    stepdate: {
        marginBottom: 16
    }
};
