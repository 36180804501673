import { useParams, useNavigate } from 'react-router-dom'
import { useGetConnectedAllQuery } from '../store/docApi'
//import { useGetHowToItemQuery } from '../store/appApi'
import { useGetLibraryItemQuery } from '../store/docApi'
import { Button, Card, CardList, Section, SectionCard, Icon } from '@blueprintjs/core'
import { appstyles } from '../styles/appstyles'
import ItemMetadata from '../Widgets/ItemMetadata'
import Picture from '../Widgets/Picture'
import Sizing from '../Widgets/Sizing'

export default function HowtoPatternDetails() {
    let params = useParams();
    const navigate = useNavigate();
    let id = params.id;
    let pk = params.pk;

    const { data: item, isError, error: patternError, isLoading } = useGetLibraryItemQuery({ label: "pattern", pk: pk, id: id });
    let _id = `pattern/${pk}/${id}`;
    const { data: projects } = useGetConnectedAllQuery({ _id: id, label: "project"});

    if (isError) console.log(_id, patternError);
    if (!item && isLoading) return <div>Fetching pattern data...</div>;

    return item && <div style={appstyles.content}>
        <div>
            <h3>{item.name}</h3>
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description && <p style={appstyles.text}>{item.description} </p>}
            {item.sizing && <Sizing sizing={item.sizing} />}
            <br/>
            <Section title="My Projects" style={{ maxWidth: 600}} 
                rightElement={<Button onClick={() => navigate(`/project/create/pattern/${item.pk}/${item.id}`)} text="New project" />}>
                <SectionCard>
                    {projects && <CardList bordered={false}>
                        {projects && projects.map((project, i) => (
                            <Card key={i} interactive onClick={() => navigate(`/project/${project.pk}/${project.id}`)}
                                 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{project.name}</span>
                                    <Icon icon='chevron-right' />
                                
                            </Card>
                        ))}
                    </CardList>}
                </SectionCard>
            </Section>
        </div>
        <ItemMetadata item={item} />
    </div>
}
