import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetContentQuery } from '../store/docApi';
import { appstyles } from '../styles/appstyles';
import { Button, Card } from '@blueprintjs/core';


const ContentPage = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState('all');
    const { data: items, error, isLoading } = useGetContentQuery();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading content</div>;

    const filteredItems = items.filter(item => {
        console.log(item);
        if (filter === 'all') return true;
        return item.label === filter;
    });

    const filters = ['all', 'pattern', 'motif', 'part', 'collection', 'palette', 'layout', 'project',
        'reference', 'howto', 'event', 'note', 'stash'
    ];

    return (
        <div style={appstyles.content}>
            <h2>Content</h2>
            <div>
                {filters.map(f => (
                    <React.Fragment key={f}>
                        <Button intent={filter === f ? 'primary' : 'none'} onClick={() => setFilter(f)}>
                            {f.charAt(0).toUpperCase() + f.slice(1)}
                        </Button>
                        &nbsp;
                    </React.Fragment>
                ))}
            </div>
            <br/>
            <div style={appstyles.tileGrid}>
                {filteredItems.map(item => (
                    <Card key={item.id} style={appstyles.tile} interactive={true}
                        onClick={() => navigate(`/${item.label}/${item.pk}/${item.id}`)}>
                        {item.picture && <img style={appstyles.tileImg} src={item.picture} alt={item.name} />}
                        <b>{item.name}</b>
                        <p>{item.label}</p>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default ContentPage;