import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetItemQuery } from '../store/docApi';
import { Button } from '@blueprintjs/core';
import FileUpload from '../Widgets/FileUpload';
import Links from '../Widgets/Links';
import { useState } from 'react';
import { useUpdatePropertyMutation } from '../store/docApi';
import { appstyles } from '../styles/appstyles';

const Yarn = () => {
    let params = useParams();
    const { data } = useGetItemQuery({ pk: params.pk, id: params.id });
    const [updateProperty] = useUpdatePropertyMutation();
    const [ item, setItem ] = useState(null);
    const [ isEditing, setIsEditing ] = useState(false);

    useEffect(() => {
        if (data) {
            const sortedData = { ...data };
            sortedData.colorways = data && data.colorways && [...data.colorways].sort((a, b) => (b.amount || 0) - (a.amount || 0));
            setItem(sortedData);
        }
    }, [data]);

    const setColor = (index, color) => {
        setItem({ ...item, colorways: item.colorways.map((cw, i) => i === index ? { ...cw, color: color } : cw) });
    }

    const setAmount = (index, amount) => {
        setItem({ ...item, colorways: item.colorways.map((cw, i) => i === index ? { ...cw, amount: amount } : cw) });
    }

    const handleColorSave = async () => {
        updateProperty({ pk: item.pk, id: item.id, name: "colorways", value: item.colorways });
        setIsEditing(false);
    };
    return (
        <div>
            {item && <div>
                <h1>{item.name}</h1>
                <p>{item.description}</p>
                <div><b>Brand:</b> {item.brand}</div>
                <div><b>Weight:</b> {item.weight}</div>
                <div><b>Yardage:</b> {item.yardage}</div>
                <div><b>Fiber:</b> {item.fiber}</div>
                {item.links && <Links links={item.links} />}
                <div style={appstyles.titleWithButtons}>
                    <h2>Available Colors</h2>
                    {!isEditing && <Button onClick={() => setIsEditing(true)}>Edit</Button>}
                    {isEditing && <Button onClick={() => handleColorSave()}>Save</Button>}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {item.colorways && item.colorways.map((colorway, index) => (
                        <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 10}}>
                            <img src={colorway.picture} alt={colorway.name} width="100" height="100"/>
                            <div style={{backgroundColor: colorway.color, width: 100, height: 20}}></div>
                            {colorway.code && <div>{colorway.code}</div>}
                            {colorway.name && <div>{colorway.name}</div>}
                            {colorway.shade && <div>{colorway.shade}</div>}
                            {colorway.amount && <div>Amount: {colorway.amount}</div>}
                            {isEditing && <input type="color" value={colorway.color} onChange={(e) => setColor(index, e.target.value)} />}
                            {isEditing && <input type="text" value={colorway.amount} onChange={(e) => setAmount(index, e.target.value)} />}
                        </div>
                    ))}
                </div>
                <FileUpload folder={item.pk} item={item} />
            </div>}
        </div>
    );
};

export default Yarn;
