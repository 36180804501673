import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, ControlGroup, FormGroup, InputGroup, Radio, RadioGroup, TextArea } from '@blueprintjs/core';
import { useCreateItemMutation } from '../store/docApi';
import { crafts, generateNumericId, getCosmosDate } from '../utils';

export default function ProjectCreate({ username }) {
  const [createItem, result] = useCreateItemMutation();
  const navigate = useNavigate();
  const params = useParams();

  const label = "project";
  const pk = username ? username : "";
  const pattern_id = params.pk && params.id ? `pattern/${params.pk}/${params.id}` : "";
  const [id, setId] = useState(params.id ? `${params.id}-${generateNumericId(4)}` : generateNumericId(8));
  const [name, setName] = useState('');
  const [description, setDescription] = useState("");
  const [craft, setCraft] = useState("knitting");

  async function handleSubmit(event) {
    event.preventDefault();
    var newProject = {
      label: label,
      id: id,
      name: name,
      craft: craft,
      pattern_id: pattern_id,
      connections: [{ _id: pattern_id, progress: {} }],
      startDate: getCosmosDate(),
    };
    if (description.trim().length !== 0)
      newProject.description = description;
    await createItem({ label: label, pk: pk, data: newProject });
  }

  useEffect(() => {
    if (result && result.isSuccess) {
      navigate(`/project/${result.data.pk}/${result.data.id}`);
    }
  }, [result, navigate]);

  return (
    <div style={styles.page}>
      {result && result.isError && <Callout intent="danger">Error saving</Callout>}
      {result && result.isSuccess && <Callout intent="success">Saved successfully!</Callout>}
      <form onSubmit={handleSubmit}>
        <h3>Create {label}</h3>
        <FormGroup label="Id">
          <ControlGroup>
            <InputGroup name="id" value={id} onChange={(e) => setId(e.target.value)} />
          </ControlGroup>
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Description">
          <TextArea fill growVertically name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormGroup>
        <RadioGroup label="Craft" onChange={(e) => setCraft(e.currentTarget.value)} selectedValue={craft} inline>
          {crafts.map((craft, i) => (
            <Radio key={i} label={craft} value={craft} />
          ))}
        </RadioGroup>
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={() => navigate(-1)}>Cancel</Button>
      </form>
    </div>
  );
}

const styles = {
  page: {
    maxWidth: 400
  }
};
