import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from '@blueprintjs/core';
import { useCreateItemMutation } from '../store/docApi';
import { generateNumericId } from '../utils';
import { appstyles } from '../styles/appstyles';

export default function CollectionCreate() {
  const [createItem, result] = useCreateItemMutation();
  const navigate = useNavigate();

  const label = "collection";
  const [id, setId] = useState(generateNumericId(8));
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    var newCollection = {
      label: label,
      id: id,
      name: name
    };
    if (description.trim().length !== 0)
      newCollection.description = description;
    await createItem({ label: label, data: newCollection });
    handleCancel();
  }

  function handleCancel() {
      navigate("/collections");
  }

  return (
    <div style={appstyles.content}>
      {result && result.isError && <Callout intent="danger">Error saving</Callout>}
      {result && result.isSuccess && <Callout intent="success">Saved successfully!</Callout>}
      <form onSubmit={handleSubmit}>
        <h3>Create Collection</h3>
        <FormGroup label="Id">
            <InputGroup name="id" value={id} onChange={(e)=>setId(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
            <InputGroup name="name" value={name} onChange={(e)=>setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Description">
            <InputGroup name="description" value={description} onChange={(e)=>setDescription(e.target.value)} />
        </FormGroup>
        <Button type="submit" intent="primary">Submit</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={handleCancel}>Cancel</Button>
      </form>
    </div>
  );
}
